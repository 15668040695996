import React, { PropsWithChildren } from 'react';
import LeftLayout from '@/layouts/Admin/Left/LeftLayout'; // Điều chỉnh đường dẫn nếu cần

const AdminLayout: React.FC<PropsWithChildren<{}>> = ({ children }) => (
  <div>
    <LeftLayout/>
  </div>
);

export default AdminLayout;

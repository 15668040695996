import React from "react";
import {AdminLayOut, AdminContent} from "@/layouts";
import {AdminProvider } from "@/components/AdminContext";


const AdminPageContent: React.FC = () => {
  return (
    <div className="d-flex" style={{ height: "100vh" }}>
      <AdminLayOut/>
      <div className="flex-grow-1 d-flex flex-column">
        <header className="bg-light p-3 border-bottom">
          <h2>Admin Header</h2>
        </header>
        <AdminContent/>
      </div>
    </div>
  );
};

const AdminPage: React.FC = () => (
  <AdminProvider>
    <AdminPageContent />
  </AdminProvider>
);

export default AdminPage;

import { useState } from "react";
import axios from 'axios';
import { LoginData } from '@/interfaces/Sign_InInfo';

const useLogin = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const signIn = async (usernameOrEmail: string, password: string): Promise<LoginData> => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.post("auth/sign_in", { usernameOrEmail, password });
            const loginData: LoginData = response.data.data;

            return loginData;
        } catch (err) {
            setError('Login fail!');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return { signIn, loading, error };
};

export default useLogin;

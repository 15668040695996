import {
  DashboardView,
  SettingsView,
  UsersView,
  SearchComponent,
} from "@/components";
import { AdminContext } from "@/components/AdminContext";
import { TypeDataAdminPage } from "@/utils/EnumConst";
import { useContext, useEffect, useState } from "react";

const AdminContent = () => {
  const adminContext = useContext(AdminContext);

  const [contentData, setContentData] = useState<any>(null);

  useEffect(() => {
    if (adminContext && adminContext.currentPage !== undefined) {
      adminContext.getDataByAdminPage(adminContext.currentPage);
    }
  }, [adminContext?.currentPage]);

  const getPageTitle = (page: TypeDataAdminPage): string => {
    switch (page) {
      case TypeDataAdminPage.adminDashBoard:
        return "Dashboard";
      case TypeDataAdminPage.manageUsers:
        return "Users";
      case TypeDataAdminPage.setting:
        return "Settings";
      default:
        return "Unknown Page";
    }
  };

  const renderContent = () => {
    const dataToRender = contentData !== null ? contentData : adminContext?.content;
    switch (adminContext?.currentPage) {
      case TypeDataAdminPage.adminDashBoard:
        return <DashboardView />;
      case TypeDataAdminPage.manageUsers:
        console.log(dataToRender);
        return <UsersView users={dataToRender} />;
      case TypeDataAdminPage.setting:
        return <SettingsView />;
      default:
        return <p>Bố m TRÊU M ĐẤY À!</p>;
    }
  };

  const handleSearchData = (data: any) => {
    setContentData(data);
  };

  return (
    <main className="flex-grow-1 p-3">
      {adminContext?.content ? (
        <>
          <SearchComponent PageTitle={getPageTitle(adminContext.currentPage)}
          onSearch={handleSearchData}  />

          {adminContext?.content ? renderContent() : <p>Loading content...</p>}
        </>
      ) : (
        <p>Loading content...</p>
      )}
    </main>
  );
};

export default AdminContent;

import { useNavigate } from 'react-router-dom';
import {Routes} from '@/utils/EnumConst';

const useRedict = () => {
  const navigate = useNavigate();

  const navigateTo = (route: Routes) => {
    navigate(route);
  };

  return { navigateTo };
};

export default useRedict;

import { useLocalStorage } from "@/hooks";
import { LoginData } from "@/interfaces/Sign_InInfo";
import React, { createContext, useState, useContext, useEffect } from "react";

interface AuthContextType {
  isAuth: boolean;
  login: (loginData: LoginData | null) => void;
  logout: () => void;
}

const defaultContextValue: AuthContextType = {
  isAuth: false,
  login: () => {},
  logout: () => {},
};

const AuthContext = createContext<AuthContextType>(defaultContextValue);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { setValue, removeValue, getValue } = useLocalStorage();

  const [isAuth, setIsAuth] = useState<boolean>(() => {
    return !!getValue();
  });

  useEffect(() => {
    if (getValue()) {
      setIsAuth(true);
    }
  }, []);

  const login = (loginData: LoginData | null) => {
    if (loginData != null) {
      setValue(loginData);
      setIsAuth(true);
    }
  };
  const logout = () => {
    removeValue();
    setIsAuth(false);
  };

  return (
    <AuthContext.Provider value={{ isAuth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

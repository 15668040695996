export enum Routes {
  HOME = "/",
  ADMIN = "/admin",
  ABOUT = "/about",
  SEARCH = "/search",
  DETAILS = "/details",
}

export enum TypeDataAdminPage {
  adminInfo,
  adminDashBoard,
  manageUsers,
  setting,
}


export enum MethodCallApi {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE"
}
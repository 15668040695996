import './App.css';

import {SideRoutes} from "@/routes";

function App() {

  return (
    <SideRoutes/>
  );
}

export default App;

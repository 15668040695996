import { useState } from 'react';

const useInput = (initialValue: string = '') => {
  const [value, setValue] = useState(initialValue);
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setIsValid(e.target.value.trim() !== '');
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  return { value, isTouched, isValid, handleChange };
};

export default useInput;

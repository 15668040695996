import { LoginData } from "@/interfaces/Sign_InInfo";
import { MethodCallApi } from "@/utils/EnumConst";
import axios, { AxiosRequestConfig } from "axios";
import useLocalStorage from "./useLocalStorage";

// Tạo một custom hook mới
const useCallApi = () => {
  const { getValue } = useLocalStorage();

  // Hàm callApi sẽ được định nghĩa bên trong custom hook
  const callApi = async (url: string, method: MethodCallApi) => {
    let loginData = getValue();
    if (loginData) {
      try {
        let data = loginData.user;
        const config: AxiosRequestConfig = {
          url,
          method,
          headers: {
            Authorization: `Bearer ${loginData.accessToken}`,
            "Content-Type": "application/json",
          },
          data,
        };

        const response = await axios(config);
        return response.data;
      } catch (error) {
        console.error("API call error:", error);
        throw error;
      }
    } else {
      throw "INVALID CALL API";
    }
  };

  return { callApi };
};

export default useCallApi;

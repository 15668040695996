import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import {AdminPage, SignIn} from '@/pages';


const SideRoutes: React.FC = () => {
  const { isAuth } = useAuth();

  return (
    <Routes>
      <Route path="/" element={isAuth ? <AdminPage /> : <Navigate to="/sign_in" />} />
      <Route path="/sign_in" element={<SignIn />} />
    </Routes>
  );
};

export default SideRoutes;

import { useContext, useState } from "react";
import "../css/Search.css";
import { filterData } from "@/hooks";
import { AdminContext } from "./AdminContext";

interface SearchComponentProps {
  PageTitle: string;
  onSearch: (data: any) => void;
}

const SearchData: React.FC<SearchComponentProps> = ({
  PageTitle,
  onSearch,
}) => {
  const adminContext = useContext(AdminContext);

  const [searchKey, setSearchKey] = useState("");

  const dataFilter = filterData(
    searchKey,
    adminContext?.content,
    adminContext?.currentPage
  );

  const handleSearch = () => {
    if (searchKey.trim() === "") {
      // Nếu searchKey trống, đặt filteredContent về null để hiển thị dữ liệu gốc
      onSearch(null);
    } else {
      onSearch(dataFilter);
    }
  };

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <form className="d-flex justify-content-center mb-4">
      <h3>{PageTitle}</h3>
      <div className="input-group search-bar">
        <input
          type="text"
          className="form-control search-input"
          placeholder="SEARCH CÁI CHÓA GÌ!"
          aria-label="Search"
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <div className="input-group-append">
          <button
            className="btn search-button"
            type="button"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchData;

import { useEffect, useState } from "react";
import { DashBoard, Setting, User } from "@/interfaces/ManageDashboard";
import { TypeDataAdminPage } from "@/utils/EnumConst";

const useSearchData = (
  tag?: string,
  data?: any,
  typeData?: TypeDataAdminPage
) => {
  const [dataFilter, setDataFilter] = useState<
    DashBoard | User | Setting | null
  >(null);

  useEffect(() => {
    const filterData = () => {
      if (!data) return;

      switch (typeData) {
        case TypeDataAdminPage.adminDashBoard:
          setDataFilter(null);
          break;
        case TypeDataAdminPage.manageUsers:
          const filterUsers: User[] = data.filter(
            (u: User) =>
              u.full_name.includes(tag ?? "") ||
              u.usernameOrEmail.includes(tag ?? "")
          );
          setDataFilter(filterUsers);
          break;
        case TypeDataAdminPage.setting:
          setDataFilter(null);
          break;
        default:
          setDataFilter(null);
          break;
      }
    };

    if (tag && data) {
      filterData();
    }
  }, [tag, data, typeData]); // Chỉ chạy khi tag, data hoặc typeData thay đổi
  return dataFilter;
};

export default useSearchData;

import { LoginData } from "@/interfaces/Sign_InInfo";

const useLocalStorage = () => {
  const setValue = (value: LoginData) => {
    try {
      window.localStorage.setItem("loginData", JSON.stringify(value));
    } catch (error) {
      console.error(error);
    }
  };

  const removeValue = () => {
    try {
      window.localStorage.clear();
    } catch (error) {
      console.error(error);
    }
  };

  const getValue = () => {
    try {
      const storedValue = localStorage.getItem("loginData");
      if (storedValue) {
        return JSON.parse(storedValue) as LoginData; // Parse chuỗi JSON về kiểu LoginData
      }
      return null;
    } catch (err) {
      console.error(err);
    }
  };

  return { setValue, removeValue, getValue };
};

export default useLocalStorage;

// AdminContext.tsx
import React, { createContext, useState, useContext, ReactNode } from "react";
import { TypeDataAdminPage, MethodCallApi } from "@/utils/EnumConst";
import {useCallApi} from "@/hooks";
import  parseDataToObject  from "@/hooks/useParseJson";

interface AdminContextProps {
  currentPage: TypeDataAdminPage;
  setCurrentPage: (page: TypeDataAdminPage) => void;
  content: any;
  getDataByAdminPage: (page: TypeDataAdminPage) => Promise<void>;
}

export const AdminContext = createContext<AdminContextProps | undefined>(
  undefined
);

interface AdminProviderProps {
  children: ReactNode;
}

export const AdminProvider: React.FC<AdminProviderProps> = ({ children }) => {
  const [currentPage, setCurrentPage] = useState<TypeDataAdminPage>(
    TypeDataAdminPage.adminInfo
  );
  const [content, setContent] = useState<any>(null);
  const { callApi } = useCallApi();
  const getDataByAdminPage = async (page: TypeDataAdminPage) => {
    try {
      setCurrentPage(page);
      let url;
      let data;
      switch (page) {
        case TypeDataAdminPage.adminDashBoard:
          url = "admin/dashboard";
          data = parseDataToObject(await callApi(url, MethodCallApi.GET), page);
          break;
        case TypeDataAdminPage.manageUsers:
          url = "admin/getAllUsers";
          data = parseDataToObject(await callApi(url, MethodCallApi.GET), page);
          break;
        case TypeDataAdminPage.setting:
          url = "admin/settings";
          data = parseDataToObject(await callApi(url, MethodCallApi.GET), page);
          break;
        default:
          throw new Error("Invalid page");
      }

      setContent(data);
    } catch (error) {
      setContent(null);
      console.error("Error fetching admin page data:", error);
    }
  };

  return (
    <AdminContext.Provider
      value={{ currentPage, setCurrentPage, content, getDataByAdminPage }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => {
  const context = useContext(AdminContext);
  if (!context)
    throw new Error("useAdmin must be used within an AdminProvider");
  return context;
};


import "../css/Sign-in.css";
import {useInput, useLogin, redictTo} from "@/hooks";
import { Routes } from "@/utils/EnumConst";
import Swal from "sweetalert2";
import { useAuth } from "@/components/AuthContext";


const SignInPage = () => {
  const account = useInput("");
  const password = useInput("");
  const { signIn,  error } = useLogin();
  const { navigateTo } = redictTo();
  const { login } = useAuth();


  const handleLogIn = async (e: React.FormEvent) => {

    e.preventDefault();
    if (account.isValid && password.isValid) {
      Swal.fire({
        title: "Đang xử lý...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const loginData = await signIn(account.value, password.value);
        Swal.close();
        Swal.fire("Complete", "Login request complete", "success");
        login(loginData);
        navigateTo(Routes.HOME);
      } catch {
        Swal.close();
        Swal.fire({
          title: "Error",
          html: `There was an error: ${error}. <a href="/register">Click here </a>`,
          icon: "error",
        });
      }
    } else {
      Swal.fire(
        "M giỡn mặt hả con ;))))",
        "thằng mất dạy nhập đàng hoàng đi",
        "warning"
      );
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-sign-in">
      <div
        className="card shadow-lg p-4"
        style={{ maxWidth: "400px", width: "100%" }}
      >
        <h1 className="text-center mb-4">Đăng Nhập MỚI ĐƯỢC VÀO RANH CON</h1>
        <form onSubmit={handleLogIn}>
          <div className="form-group">
            <label htmlFor="email">Email/Account</label>
            <input
              type="text"
              id="email"
              className="form-control"
              placeholder="Nhập email đi ranh con"
              value={account.value}
              onChange={account.handleChange}
            />
          </div>
          {account.isTouched && (
            <div style={{ color: account.isValid ? "green" : "red" }}>
              {account.isValid ? "Giỏi lắm con zai." : "Bố đùa m đấy à!!!"}
            </div>
          )}
          <div className="form-group">
            <label htmlFor="password">Mật khẩu</label>
            <input
              type="password"
              id="password"
              className="form-control"
              placeholder="ĐỂ t nói ít thôi nhập đi"
              value={password.value}
              onChange={password.handleChange}
            />
          </div>
          {password.isTouched && (
            <div style={{ color: password.isValid ? "green" : "red" }}>
              {password.isValid ? "Giỏi lắm con zai." : "Nhập đàng hoàng!!!"}
            </div>
          )}
          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={!account.isValid || !password.isValid}
          >
            Đăng Nhập
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignInPage;

import { TypeDataAdminPage } from "@/utils/EnumConst";

const parseDataToObject = <T>(data: any, type: TypeDataAdminPage): T => {
    switch (type) {
      case TypeDataAdminPage.manageUsers:
        return data.map((item: any) => ({
          id: item.id,
          full_name: item.full_name,
          usernameOrEmail: item.usernameOrEmail,
          avatar: item.avatar,
          bio: item.bio,
        })) as T;
      case TypeDataAdminPage.adminDashBoard:
        return {
          stats: data.stats,
          charts: data.charts,
        } as T;
      default:
        throw new Error("Unsupported type");
    }
  };

export default parseDataToObject;



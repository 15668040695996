import React, { useEffect, useState } from "react";
import { useAdmin } from "@/components/AdminContext";
import { redictTo} from "@/hooks";
import { Routes, TypeDataAdminPage, MethodCallApi } from "@/utils/EnumConst";
import { useAuth } from "@/components/AuthContext";
import { User } from "@/interfaces/ManageDashboard";
import useCallApi from "@/hooks/useCallApi";

const LeftLayout: React.FC = () => {
  const { navigateTo } = redictTo();
  const { logout } = useAuth();
  const { getDataByAdminPage  } = useAdmin();
  const [adminInfo, setAdminInfo] = useState<User | null>(null);
  const {callApi}= useCallApi();

  useEffect(() => {
    const fetchAdminInfo = async ()  => {
      try {
        const data = await callApi("users/me", MethodCallApi.GET);
        setAdminInfo(data.data);
      } catch (error) {
        console.error("Failed to fetch admin info", error);
      }
    };

    fetchAdminInfo();
  }, []);

  const handleLogout = () => {
    logout();
    navigateTo(Routes.HOME);
  };

  const handleNavigation = (page: TypeDataAdminPage) => {
    getDataByAdminPage(page);
  };


  return (
    <div className="bg-light p-3 border-end" style={{ width: "250px" }}>
      {adminInfo ? (
        <div className="mb-4 text-center">
          <img
            src={adminInfo.avatar}
            alt="Admin Avatar"
            className="rounded-circle mb-2"
            style={{ width: "80px", height: "80px" }}
          />
          <h5>{adminInfo.full_name}</h5>
        </div>
      ) : (
        <p>Loading...</p>
      )}

      <ul className="nav flex-column">
        <li className="nav-item">
          <a
            className="nav-link"
            href="#"
            onClick={() => handleNavigation(TypeDataAdminPage.adminDashBoard)}
          >
            Dashboard
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            href="#"
            onClick={() => handleNavigation(TypeDataAdminPage.manageUsers)}
          >
            Account/Users
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            href="#"
            onClick={() => handleNavigation(TypeDataAdminPage.setting)}
          >
            Settings
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#" onClick={handleLogout}>
            LogOut
          </a>
        </li>
      </ul>
    </div>
  );
};

export default LeftLayout;

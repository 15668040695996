import React, { useContext, useEffect } from "react";
import AdminLayout from "@/layouts/Admin/AdminLayout";
import { AdminContext, AdminProvider } from "@/components/AdminContext";
import { TypeDataAdminPage } from "@/utils/EnumConst";
import { User } from "@/interfaces/ManageDashboard";

const ManageUsersTable: React.FC<{ users: User[] }> = ({ users }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Avatar</th>
          <th>Username or Email</th>
          <th>Bio</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <tr key={user.id}>
            <td>
              <div className="d-flex align-items-center">
                <img
                  src={user.avatar}
                  alt={user.full_name}
                  className="rounded-circle"
                  width="40"
                  height="40"
                />
                <span className="ml-2">{user.full_name}</span>
              </div>
            </td>
            <td>{user.usernameOrEmail}</td>
            <td>{user.bio}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ManageUsersTable;